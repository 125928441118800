import { HeaderLinkProps } from "@fonk-gitlab/bpfd-portal/dist/components/organisms/header/Header.types";

export enum Gender {
  F = "F",
  M = "M",
}

export interface Person {
  userName: string;
  relationshipStartDate: string;
  phoneNumber: string;
  personId: string;
  partnerMiddleName: string | null;
  partnerLastName: string | null;
  partnerGender: Gender;
  partnerFirstName: string | null;
  partnerDateOfBirth: string | null;
  partnerBsn: string | null;
  nonBRPForeignCountry: string | null;
  nonBRPForeignAddress3: string | null;
  nonBRPForeignAddress2: string | null;
  nonBRPForeignAddress1: string | null;
  middleName: string | null;
  maritalStatus: MaritalStatus;
  lastName: string;
  language: string;
  id: string;
  givenName: string;
  gender: Gender;
  firstName: string;
  email: string;
  dateOfBirth: string;
  bsn: string;
  brpStreetName: string | null;
  brpPostalCode: string | null;
  brpHouseNumberSuffix: string | null;
  brpHouseNumber: string | null;
  brpForeignAddress3: string | null;
  brpForeignAddress2: string | null;
  brpForeignAddress1: string | null;
  brpCountry: string;
  brpCityName: string;
  messages: Message[];
}

export interface Message {
  textUS: string;
  textNL: string;
}

export interface Pension {
  retiredPension: RetiredPension | null;
  nonRetiredPension: NonRetiredPension | null;
  isRetired: boolean;
}

export interface NonRetiredPension {
  retirementDate: string;
  opToBeReachedNet: number;
  opToBeReachedAOWNet: number;
  opToBeReachedAOW: number;
  opToBeReached: number;
  netAmountWithAOW: number;
  netAmount: number;
  grossAmountWithAOW: number;
  grossAmount: number;
  defaultRetirementYears: number;
  defaultRetirementMonths: number;
  aowRetirementYears: number;
  aowRetirementMonths: number;
  aow: number;
}

export interface RetiredPension {
  netAmount: number;
  monthPeriod: number;
  grossAmount: number;
  dayPeriod: number;
}

export interface Preferences {
  corAddress1: string | null;
  corAddress2: string | null;
  corAddress3: string | null;
  corCountry: string | null;
  email: string;
  endDate: string | null;
  givenName: string;
  isReceiveNews: boolean;
  isSameAddress: boolean;
  language: string;
  personId: string;
  personUUID: string;
  phoneNumber: string;
  startDate: string;
  wayOfCommunication: string;
}

export enum MaritalStatus {
  MARRIAGE = "Marriage",
  PARTNERSHIP = "Partnership",
  COHABITATION = "Living together",
  CHILD = "Child",
}

export interface CustomDocument {
  name: string;
  status: string;
  createdDate: string;
  storageName: string;
  container: string;
}

export enum SituationCategory {
  DEFAULT = "Default",
  SALARY = "Salary",
  AOW = "AOW",
}

export enum SitName {
  SALARY = "salary",
  AOW = "aow",
  PENSION = "pension",
  SURRENDER = "surrender",
  TOTAL = "",
  DEFAULT = "DEFAULT",
}

export interface AmountPayload {
  name: SitName;
  currentGross: number | null;
}

export interface Amount extends AmountPayload {
  currentNet: number | null;
  calculatedGross: number | null;
  calculatedNet: number | null;
  referenceValue: number | null;
  info?: string;
  isTotal?: boolean;
}

export interface SituationPayload {
  category: SituationCategory;
  amounts: AmountPayload[];
  ageInMonths: number;
  showTotal: boolean;
}

export interface Situation extends Omit<SituationPayload, "amounts"> {
  amounts: Amount[];
  totalCurrentNet: number | null;
  totalCurrentGross: number | null;
  totalCalculatedNet: number | null;
  totalCalculatedGross: number | null;
}

export enum ParticipationStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum ExchangeType {
  OPPP = "OPPP",
  PPOP = "PPOP",
}

export interface Employment {
  salary: number;
  hours: number;
  providerParticipantPhoneNumber: string;
}

export interface Participation {
  status: ParticipationStatus;
  defaultRetirementDate: string;
  type: string;
  hasActiveRetirementCase: boolean;
}

export interface Exchange {
  typeOfExchange: ExchangeType;
  isWarningOPPP: boolean;
  isSurrenderAtRetirement: boolean;
  isSelected: boolean;
  desiredExchange: number;
  currentPP: number;
  currentOP: number;
  afterExchangePP: number;
  afterExchangeOP: number;
  maxExchangePP: number;
  maxExchangeOP: number;
}

export interface ProviderRetirementOptions {
  isLowHigh: boolean;
  isHighLow: boolean;
  isExchangePPOP: boolean;
  isExchangeOPPP: boolean;
  isBridging: boolean;
}

export interface Retirement {
  maxDefaultRetirementAgeInMonths: number;
  maxRetirementAgeInMonths: number;
  minPartlyRetirementAgeInMonths: number | null;
  minRetirementAgeInMonths: number;
  minSurrenderAgeInMonths: number | null;
  maxExchangeBridging: number;
  hasPayableOP: boolean;
  exchange: Exchange;
  providerOptions: ProviderRetirementOptions;
}
export interface Scenario {
  salaryStartYear: number | null;
  salaryStartMonth: number | null;
  salary: number | null;
  retirementStartYear: number;
  retirementStartMonth: number;
  quitWorkingStartYear: number | null;
  quitWorkingStartMonth: number | null;
  partTimePercentageStartYear: number | null;
  partTimePercentageStartMonth: number | null;
  partTimePercentage: number | null;
  partlyRetirementStartYear: number | null;
  partlyRetirementStartMonth: number | null;
  partlyRetirementPercentage: number | null;
  isMaxExchangeOPPP: boolean;
  isMaxExchangeAOW: boolean;
  convertSurrenderToNormal: boolean;
  differentHeightsOverTime: string | null;
  differentHeightsEndYearInMonth: number | null;
  exchangeEntitlementsOption: string | null;
  exchangeEntitlementsAmount: number | null;
  bridgingAmount: number | null;
  surrenderYear: number | null;
  surrenderMonth: number | null;
}

export interface UnfilteredScenario extends Scenario {
  lumpSumAmount: number | null;
  indicationMaxLumpSum: boolean;
  indicationEndEmployment: boolean;
  dateLumpSum: number | null;
  isPartnerDigitalSigned: boolean;
  isParticipantDigitalSigned: boolean;
  currentExternalContractHours: number | null;
  currentExternalSalary: number | null;
  indicationSurrender: boolean;
}

export interface Planner {
  employment: Employment;
  participation: Participation;
  pension: {
    rangesPP: string;
    rangesWZP: string;
  };
  person: {
    dateOfBirth: string;
    disabled: boolean | null;
    hasTaxException: boolean | null;
    personalBankAccount: PersonalBankAccount;
  };
  relation: {
    partnerId: string | null;
  };
  retirement: Retirement;
  situations: Situation[];
  scenario: UnfilteredScenario | null;
}

export interface YearsAndMonthsLimit {
  years: number;
  months: number;
}

export interface SurrenderOptionLimits {
  ageYearsAndMonths: {
    min: YearsAndMonthsLimit;
    max: YearsAndMonthsLimit;
  };
  calendarYearAndMonth: {
    min: Pick<YearsAndMonthsLimit, "years">;
    max: Pick<YearsAndMonthsLimit, "years">;
  };
}

export interface RetirementOptionsLimits {
  earliestRetirementAgeYears: number;
  earliestRetirementAgeMonths: number;
  earliestRetirementCalendarYear: number;
  latestRetirementAgeYears: number;
  latestRetirementAgeMonths: number;
  latestRetirementCalendarYear: number;
  earliestPartlyRetirementAgeYears: number;
  earliestPartlyRetirementAgeMonths: number;
  earliestPartlyRetirementCalendarYear: number;
  latestDefaultRetirementAgeYears: number;
  latestDefaultRetirementAgeMonths: number;
  latestDefaultRetirementCalendarYear: number;
  earliestSurrenderRetirementAgeYears?: number;
  earliestSurrenderRetirementAgeMonths?: number;
  earliestSurrenderRetirementCalendarYear?: number;
  defaultMaximumExchangeOP: number;
  defaultMaximumExchangePP: number;
}

export interface LimitsAge {
  currentAge: YearsAndMonthsLimit;
  retirementAge: YearsAndMonthsLimit;
}

export interface PlannerKeyParams {
  limitAge: LimitsAge;
  retirementYear: number;
  retirementOptionsLimits: RetirementOptionsLimits;
  isActive: boolean;
}

export interface PageLink extends HeaderLinkProps {
  index: number;
}

export interface Toggles {
  hasPassingAwayCase: boolean;
  hasDivorceCase: boolean;
  enablePlanner: boolean;
  hasEndEmploymentCase: boolean;
  hasMissingBankAccountCase: boolean;
  hasRetirementCase: boolean;
  hasValueTransferCase: boolean;
}

export enum EmploymentOptionKeys {
  WorkingTimeOption = "workingTimeOption",
  EarningSalaryOption = "earningSalaryOption",
  QuittingWorkOption = "quittingWorkOption",
}

export interface Survivor {
  survivorId: string;
  pensionEmail: string;
  pensionAddress: string;
  isTaxCheck: boolean;
  isStudent: boolean;
  isSicknessBenefit: boolean;
  isOrphan: boolean;
  isWzp2025Apply: boolean;
  isAdult: boolean;
  genders: SurvivorGenders;
  gender: string | null;
  deceasedName: string;
  deceasedId: string;
  dateOfDeathParticipant: string;
  children: Child[] | null;
  checkANW: boolean;
  caseId: string;
  brpStreetName: string;
  brpPostalCode: string;
  brpHouseNumberSuffix: string;
  brpHouseNumber: string;
  brpCityName: string | null;
  bankAccount?: BankAccount;
}

export type SurvivorPayload = Partial<Survivor & { fosterChildren: FosterChild[]; isANWCheck: boolean }>;

export interface FosterChild {
  firstName: string;
  lastName: string;
  birthdate: string;
  bsn: string;
  gender: string;
}

export interface Child {
  firstName: string;
  lastName: string;
  birthdate: string;
  bsn: string;
  id: string;
}

export interface SurvivorGenders {
  Female: "V";
  Male: "M";
}

export interface BankAccount {
  bankBicCode?: string | null;
  isBankAccountEU?: boolean | null;
  bankName?: string | null;
  bankLocation?: string | null;
  bankCountryName?: string | null;
  bankAddress?: string | null;
  bankAccountNumber?: string | null;
}

export interface PersonalBankAccount {
  isBankAccountEU: boolean | null;
  bankAccount: string | null;
  bankAddress: string | null;
  bankBicCode: string | null;
  bankName: string | null;
  countryCode: number | null;
  locationBank: string | null;
}

export interface EmploymentOptions {
  isWorkingTimeSelected: boolean;
  isEarningSalarySelected: boolean;
}

export interface EarlyOrLaterOption {
  percentage: number;
  partlyYears: number | null;
  partlyMonths: number | null;
  isSelected: boolean;
  fullyYears: number | null;
  fullyMonths: number | null;
}

export interface ExchangeOption {
  isSelected: boolean;
  exchangeType: string | null;
  isMaxExchangeSelected: boolean;
  exchangeAmount: number | null;
}

export interface BridgingOption {
  isSelected: boolean;
  exchangeAmount: number | null;
}

export interface HighLowOption {
  type: string | null;
  months: number | null;
  isSelected: boolean;
}

export interface RetirementOptions {
  earlyOrLater: EarlyOrLaterOption;
  exchange: ExchangeOption;
  highLow: HighLowOption;
}

export type EmploymentAndRetirementOptions = EmploymentOptions & RetirementOptions;

export interface PlannerBridgingPayload {
  salary: number;
  hours: number;
  employmentOptions: EmploymentOptions;
  retirementOptions: RetirementOptions;
}

export interface RetirementChoices {
  bankAccount: BankAccount;
  personId: string;
  partnerId: string | null;
  taxExemption: boolean;
}
export interface PlannerSaveConfirmChoices {
  retirementChoice: RetirementChoices;
}

export interface Country {
  countryCode: string;
  countryNameEN: string;
  countryNameNL: string;
}

export type CountryByUserLng = Pick<Country, "countryCode"> & { countryName: string };

export enum YesNoValueEnum {
  YES = "yes",
  NO = "no",
}

export type EmploymentRenamed = Record<
  string,
  {
    isSelected?: boolean;
    ageMonth?: number;
    ageYear?: number;
    calendarYear?: number;
    calendarMonth?: number;
  }
>;

export interface ExchangeArrayItem {
  checked: boolean;
  value: string;
}

export type RetirementRenamed = Record<
  string,
  {
    isSelected?: boolean;
    isRefrainFromSurrenderSelected?: boolean;
    isSurrenderSelected?: boolean;
    isEarlierOrLaterRetirementSelected?: boolean;
    fullyOrPartial?: { value: string };
    fullCalendarMonth?: number;
    fullCalendarYear?: number;
    fullAgeYear?: number;
    fullAgeMonth?: number;
    partialAgeYear?: number;
    partialAgeMonth?: number;
    maxExchangeAowAmount?: number;
    calendarMonth?: number;
    calendarYear?: number;
    numberOfMonths?: number;
    Exchange?: Record<string, ExchangeArrayItem>;
    exchangeType?: string | GenericRadioItem[] | null;
    exchangeAmount?: number;
    isMaxExchange?: GenericRadioItem[] | boolean;
  }
>;

export interface EmploymentAndRetirementRenamed {
  retirement: RetirementRenamed;
  employment: EmploymentRenamed;
}

export interface PlannerUpdatePayload {
  hours: number;
  salary: number;
  situations: SituationPayload[];
}

export interface EmploymentAndRetirementPayload {
  employment?: EmploymentPayload;
  retirement?: RetirementPayload;
}

export type PlannerCalculatePayload = PlannerUpdatePayload & EmploymentAndRetirementPayload;

interface IsSelected {
  isSelected: boolean;
}

export interface EmploymentPayload {
  WorkingTime?: WorkingTimePayload;
  EarningSalary?: EarningSalaryPayload;
  QuittingWork?: QuittingWorkPayload;
}

interface YearsAndMonths {
  years?: number;
  months?: number;
}
interface WorkingTimePayload extends IsSelected, YearsAndMonths {
  hours?: number;
}
interface EarningSalaryPayload extends IsSelected, YearsAndMonths {
  salary?: number;
}
type QuittingWorkPayload = IsSelected & YearsAndMonths;

export interface RetirementPayload {
  RefrainSurrender?: RefrainSurrenderPayload;
  earlyOrLater?: EarlyOrLaterPayload;
  HighLow?: HighLowPayload;
  Exchange?: ExchangeObjectPayload;
  Bridging?: BridgingPayload;
}

type RefrainSurrenderPayload = IsSelected;

interface EarlyOrLaterPayload extends IsSelected {
  percentage?: number;
  fullyYears: number;
  fullyMonths: number;
  partlyYears: number;
  partlyMonths: number;
}

interface ExchangeObjectPayload extends IsSelected {
  exchangeType?: ExchangeType | null;
  exchangeAmount?: number;
  isMaxExchange?: boolean;
  exchangeAmountOriginal?: number;
}

interface BridgingPayload extends IsSelected {
  exchangeAmount?: number;
  isMaxExchangeAOW?: boolean;
}

interface HighLowPayload extends IsSelected {
  type?: string | null;
  months?: number;
}

export enum UseMaximumExchange {
  YES = "yes",
  NO = "no",
}

export interface GenericRadioItem {
  id: string;
  checked: boolean;
  value?: string;
  label?: string;
}
