import { FormImportantMessage, IntroPanel } from "@fonk-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Message, Person, Toggles } from "../../lib/types";
import { getHomepageWarnings } from "../../react-query/queries";
import { HREF } from "../../routes/routes";
import { PortalLanguage } from "../../utils/languageLoader";
import { useHandleAuthError } from "./ErrorHandlingHelper";

const FormattedWarnings = (warnings: Message[], locale: string) => {
  return (
    <ul style={{ marginBottom: "0px" }}>
      {warnings?.map((warning: Message) => {
        const value = locale === PortalLanguage.NL ? warning.textNL : warning.textUS;
        return <li key={value}>{value}</li>;
      })}
    </ul>
  );
};

interface IntroPanelHomeProps {
  user: Person;
  toggles: Toggles;
}

const caseOrder = {
  hasPassingAwayCase: 1,
  hasMissingBankAccountCase: 2,
  hasDivorceCase: 3,
  hasValueTransferCase: 4,
  hasEndEmploymentCase: 5,
  hasRetirementCase: 6,
  enablePlanner: 7,
};
const caseDetails = {
  1: {
    buttonText: <FormattedMessage id="home.passing-away" />,
    hrefLink: HREF.PASSING_AWAY,
    greetingText: <FormattedMessage id="utils.dear" />,
    introText: <FormattedMessage id="home.intro-passing-away" />,
  },
  2: {
    buttonText: <FormattedMessage id="home.missing-bank-account" />,
    hrefLink: HREF.EMPTY,
    greetingText: <FormattedMessage id="utils.welcome" />,
    introText: <FormattedMessage id="home.intro-missing-bank-account" />,
  },
  3: {
    buttonText: <FormattedMessage id="home.divorce" />,
    hrefLink: HREF.EMPTY,
    greetingText: <FormattedMessage id="utils.welcome" />,
    introText: <FormattedMessage id="home.intro-divorce" />,
  },
  4: {
    buttonText: <FormattedMessage id="home.value-transfer" />,
    hrefLink: HREF.EMPTY,
    greetingText: <FormattedMessage id="utils.welcome" />,
    introText: <FormattedMessage id="home.intro-value-transfer" />,
  },
  5: {
    buttonText: <FormattedMessage id="home.end-employment" />,
    hrefLink: HREF.EMPTY,
    greetingText: <FormattedMessage id="utils.welcome" />,
    introText: <FormattedMessage id="home.intro-end-employment" />,
  },
  6: {
    buttonText: <FormattedMessage id="home.retirement" />,
    hrefLink: HREF.EMPTY,
    greetingText: <FormattedMessage id="utils.welcome" />,
    introText: <FormattedMessage id="home.intro-retirement" />,
  },
};

const determineCaseOrder = (toggles: Toggles) => {
  if (!toggles) return;
  return Object.keys(toggles)
    .filter((key) => toggles[key as keyof Toggles])
    .reduce(
      (highest, key) => {
        const currentKey = key as keyof typeof caseOrder;
        const highestKey = highest as keyof typeof caseOrder;
        return highest === null || caseOrder[currentKey] < caseOrder[highestKey] ? key : highest;
      },
      "" as string | null
    );
};

const IntroPanelHome: React.FC<IntroPanelHomeProps> = ({ user, toggles }) => {
  const { locale } = useIntl();
  const navigate = useNavigate();
  const {
    data: warnings,
    error,
    refetch,
  } = useQuery({
    queryKey: ["warnings"],
    queryFn: getHomepageWarnings,
    select: (data) => data.messages as Message[],
  });

  useHandleAuthError(error, refetch);

  const name = `${user?.firstName.slice(0, 1)}. ${user?.lastName}`;
  const hasWarnings = () => warnings && warnings.length > 0;
  const action = determineCaseOrder(toggles as Toggles);
  const caseKey: number | null = caseOrder[action as keyof typeof caseOrder];

  let buttonText = <FormattedMessage id="home.compare-situations" />;
  let hrefLink = HREF.PLANNER;
  let greetingText = <FormattedMessage id="utils.welcome" />;
  let introText = <FormattedMessage id="home.intro-panel-body" />;

  if (action && caseKey && caseKey !== 7) {
    const detail = caseDetails[caseKey as keyof typeof caseDetails];
    buttonText = detail.buttonText;
    hrefLink = detail.hrefLink;
    greetingText = detail.greetingText;
    introText = detail.introText;
  }

  const bodyText = hasWarnings() ? "" : introText;
  const handleButtonClick = () => {
    navigate(hrefLink);
  };

  return (
    <IntroPanel
      introductionText={greetingText}
      name={name}
      body={bodyText}
      buttonText={buttonText}
      onButtonClick={handleButtonClick}
      warnings={
        hasWarnings() ? (
          <FormImportantMessage
            heading={<FormattedMessage id="home.intro-panel-warning" />}
            body={FormattedWarnings(warnings || [], locale)}
            className="my-2"
          />
        ) : null
      }
    />
  );
};

export default IntroPanelHome;
